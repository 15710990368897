.upperNav {
    width: 1000px;
    margin: 0 auto;
    & .navItem {
        width: 110px;
        height: 56px;
        line-height: 56px;
        text-align: center;
        background-color: #333;
        margin-right: 1px;
        cursor: pointer;
        float: left;
        & a {
            color: #fff;
            text-decoration: none;
            font-size: 0.9375rem;
        }
        &:hover a {
            color: #b79e6f;
        }
    }
}
