.Action {
    width: 690px;
    float: left;
    overflow: hidden;
    & a {
        text-decoration: none;
    }
}
.listItem {
    width: 338px;
    height: 430px;
    float: left;
    overflow: hidden;
    margin-right: 7px;
}
.text {
    width: 80.4%;
    margin: 0 auto;
    margin-top: 24px;
    color: #3b3b3b;
    font-size: 1rem;
    line-height: 1.25rem;
    text-decoration: none;
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-box-orient: vertical;
    -webkit-line-clamp: 3;
    &:hover {
        color: #f54343;
    }
}
