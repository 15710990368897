.underNav {
    width: 1000px;
    margin: 0 auto;
    position: relative;
    z-index: 200;
}
.navItem {
    width: 110px;
    height: 87px;
    text-align: center;
    float: left;
    & a {
        padding-bottom: 6px;
        display: inline-block;
        line-height: normal;
        cursor: pointer;
        font-size: 0.75rem;
        color: #000000;
        padding-top: 34px;
    }
    &:hover a {
        text-decoration: none;
        padding-bottom: 6px;
        display: inline-block;
        line-height: normal;
        border-bottom: 1px solid #b79e6f;
        font-weight: bold;
        font-size: 0.875rem;
        color: #444547;
        padding-top: 33px;
    }
}
.active {
    padding-bottom: 6px;
    display: inline-block;
    line-height: normal;
    border-bottom: 1px solid #b79e6f;
    font-weight: bold;
    font-size: 0.875rem !important;
    color: #444547;
    padding-top: 33px !important;
}
.last_nav_logo {
    text-align: center;
    padding-top: 30px;
    float: left;
    width: 80px;
    height: auto;
    position: absolute;
    right: 0px;
    z-index: 101;
    &:hover .qrCode {
        display: block;
    }
}
.hide {
    display: none;
}
.qrCode {
    display: none;
    width: 240px;
    height: auto;
    position: absolute;
    right: -66px;
    top: 126px;
    z-index: 100;
    cursor: pointer;
    & img {
        width: 100%;
    }
}
