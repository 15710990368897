.ad_top {
    width: 1000px;
    margin: 0 auto;
}
.qrCode {
    position: fixed;
    z-index: 101;
    right: 116px;
    top: 637px;
    & img {
        width: 100%;
    }
}
.hide {
    display: none;
}
