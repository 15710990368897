.topSlideWrap {
    width: 1000px;
    margin: auto;
    height: 100%;
    cursor: pointer;
    position: relative;
    overflow: hidden;
    & [ifeng_ui_pc_slideers-current='false'] {
        & .slideItem .mask {
            display: block;
            *+display: none;
        }
        & .text_wrap {
            display: none;
            *+display: block;
        }
    }
    & [ifeng_ui_pc_slideers-current='true'] {
        & .slideItem .mask {
            display: none;
        }
        & .text_wrap {
            display: block;
        }
    }
}
.slideContaniner {
    width: 1950px;
    position: relative;
    left: 50%;
    margin-left: -975px;
}
.slideItem {
    width: 650px;
    height: 400px;
    position: relative;
}
.mask {
    width: 100%;
    height: 100%;
    position: absolute;
    opacity: 0.5;
    filter: alpha(opacity=50);
    background-color: #000;
    top: 0;
    left: 0;
    z-index: 99;
    display: block;
    *display: none;
}
.hide {
    display: none;
}
[ifeng_ui_pc_sliders-arrows_pre] {
    width: 20px !important;
    height: 35px !important;
    position: absolute !important;
    top: 50% !important;
    margin-top: -10px !important;
    left: 550px !important;
    cursor: pointer !important;
    z-index: 100 !important;
    background: rgba(0, 0, 0, 0) url(http://p0.ifengimg.com/fe/gongyi_home_page/images/left-arrow-c1_c054bda1.png)
        no-repeat center center !important;
    background-size: contain !important;
}
[ifeng_ui_pc_sliders-arrows_next] {
    right: 550px !important;
    width: 20px !important;
    height: 35px !important;
    position: absolute !important;
    top: 50% !important;
    margin-top: -10px !important;
    cursor: pointer !important;
    z-index: 100 !important;
    background: rgba(0, 0, 0, 0) url(http://p0.ifengimg.com/fe/gongyi_home_page/images/right-arrow-c1_ce4eab1d.png)
        no-repeat center center !important;
    background-size: contain !important;
}
.text_wrap {
    width: 412px;
    height: 106px;
    position: absolute;
    left: 0;
    bottom: 28px;
}
.text {
    width: 364px;
    font-size: 1.625rem;
    font-weight: bold;
    padding: 18px 24px;
    z-index: 9;
    height: 70px;
    position: absolute;
    left: 0;
    top: 0;
    overflow: hidden;
    & a {
        color: #b79e6f;
        text-decoration: none;
        &:hover {
            color: #fff;
        }
    }
}
.text_bg {
    background-color: #000;
    opacity: 0.6;
    filter: alpha(opacity=60);
    width: 100%;
    height: 106px;
}
[ifeng_ui_pc_sliders-dots] {
    position: absolute;
    left: 50% !important;
    bottom: -40px !important;
    right: auto !important;
    margin-left: -80px !important;
}
.dot {
    width: 10px !important;
    height: 10px !important;
    background-color: #fff;
    -webkit-border-radius: 50%;
    -moz-border-radius: 50%;
    border-radius: 50%;
    margin-right: 24px !important;
    float: left;
}
.current {
    width: 10px !important;
    height: 10px !important;
    background-color: #f54343 !important;
    -webkit-border-radius: 50%;
    -moz-border-radius: 50%;
    border-radius: 50%;
    margin-right: 24px !important;
    float: left;
}
