.News {
    width: 1000px;
    margin: 0 auto;
}
.left_img {
    width: 360px;
    height: 500px;
    overflow: hidden;
    margin-right: 12px;
    position: relative;
    float: left;
    &:hover a {
        text-decoration: none;
    }
    & a img {
        display: block;
    }
}
.small_list {
    width: 310px;
    position: relative;
    float: left;
    margin-right: 3.8px;
    margin-bottom: 9.6px;
    &:hover a {
        text-decoration: none;
    }
    & a img {
        display: block;
    }
}
.right_img {
    width: 628px;
    float: left;
}
.text_bg {
    background: #000;
    opacity: 0.6;
    -moz-opacity: 0.6;
    filter: alpha(opacity=60);
    width: 100%;
    height: 102.99px;
    overflow: hidden;
    position: absolute;
    left: 0;
    bottom: 0;
    transition: all 0.5s;
}
.text {
    position: absolute;
    left: 0;
    bottom: 0;
    width: 90%;
    margin: 0 auto;
    font-size: 1.25rem;
    color: #fff;
    line-height: 1.75rem;
    padding: 0px 19.2px;
    padding-top: 24px;
    padding-bottom: 24px;
    height: 55px;
    &:hover {
        color: #b79e6f;
        text-decoration: none;
    }
}
