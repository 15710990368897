.gongyiHeader {
    width: 1000px;
    margin: 0 auto;
    padding-top: 50px;
    padding-bottom: 40px;
    height: 60px;
    flex-wrap: nowrap;
    position: relative;
}
.logo {
    height: 100%;
    margin-right: 22px;
    float: left;
    padding-top: 20px;
}
.logo img {
    width: 161px;
    height: 27px;
}
.logo_2 {
    float: left;
    height: 100%;
    margin-right: 80px;
}
.logo_3 {
    float: left;
    height: 100%;
}
.navFind {
    position: relative;
    z-index: 80;
    float: right;
    margin-top: 17px;
    margin-left: 78px;
}
