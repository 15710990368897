.Innovation {
    float: left;
    width: 686px;
    overflow: hidden;
    position: relative;
    padding-bottom: 52px;
    /* & [ifeng_ui_pc_slideers-current='false'] {
        & .text_wrap {
            display: none;
        }
    }
    & [ifeng_ui_pc_slideers-current='true'] {
        & .text_wrap {
            display: block;
        }
    } */
    & .slideItem {
        position: relative;
    }
}
.text_wrap {
    width: 412px;
    height: 106px;
    position: absolute;
    left: 0;
    bottom: 28px;
}
.text {
    width: 364px;
    font-size: 1.625rem;
    font-weight: bold;
    padding: 18px 24px;
    z-index: 9;
    height: 70px;
    position: absolute;
    left: 0;
    top: 0;
    overflow: hidden;
    & a {
        color: #b79e6f;
        text-decoration: none;
        &:hover {
            color: #fff;
        }
    }
}
.text_bg {
    background-color: #000;
    opacity: 0.6;
    filter: alpha(opacity=60);
    width: 100%;
    height: 106px;
}
[ifeng_ui_pc_sliders-dots] {
    position: absolute;
    left: 50% !important;
    bottom: -40px !important;
    right: auto !important;
    margin-left: -80px !important;
}
.dot {
    width: 10px !important;
    height: 10px !important;
    background-color: #3b3b3b !important;
    -webkit-border-radius: 50%;
    -moz-border-radius: 50%;
    border-radius: 50%;
    margin-right: 24px !important;
    float: left;
}
.current {
    width: 10px !important;
    height: 10px !important;
    background-color: #f54343 !important;
    -webkit-border-radius: 50%;
    -moz-border-radius: 50%;
    border-radius: 50%;
    margin-right: 24px !important;
    float: left;
}
