.VideoBox {
    width: 300px;
    height: 470px;
    overflow: hidden;
    float: right;
}
.playList {
}
.playListItem {
    padding-left: 28.8px;
    line-height: 22px;
    margin-top: 11px;
    background: url(http://p0.ifengimg.com/fe/gongyi_home_page/images/list-play_ec071a79.jpg) left top no-repeat;
    background-position-y: 4px;
}
.playListItem_1 {
    padding-left: 28.8px;
    line-height: 22px;
    margin-top: 16px;
    background: url(http://p0.ifengimg.com/fe/gongyi_home_page/images/list-play_ec071a79.jpg) left top no-repeat;
    background-position-y: 4px;
}
.title {
    display: block;
    color: #b79e6f !important;
    text-decoration: none;
    font-size: 0.875rem;
    width: 270px;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
    &:hover {
        text-decoration: none;
        color: #f54343 !important;
    }
}
