.surveyBox {
    float: right;
    width: 298px;
    height: 832px;
    background-image: url('./surveyBg.jpg');
    /* position: absolute;
    right: -5px; */
    border-radius: 10px;
}

.survey {
    position: relative;
    padding: 36px 20px 0;
}

.title {
    padding: 12px 0;
    border-bottom: 1px solid #0b0909;
    font: bold 16px '微软雅黑';

    & a {
        color: #3b3b3b;
        text-decoration: none;
    }
}

.num {
    height: 23px;
}

.option {
    & li {
        padding-top: 10px;
    }
}

.bline {
    border-bottom: 1px solid #0b0909;
}

.letter {
    display: inline-block;
    float: left;
    width: 23px;
    height: 23px;
    font: 12px Arial;
    line-height: 23px;
    text-align: center;
    color: #fff;
    background-color: #d5797f;
}

.line {
    float: left;
    margin: 10px 8px 0;
    width: 80px;
    height: 0;
    border-top: 2px solid #d5797f;
    border-left: none;
    border-right: none;
    border-bottom: none;
}

.nump {
    float: left;
    display: inline-block;
    margin-top: 4px;
    font: 16px Arial;
    color: #d5797f;
}

.optionTitle {
    font: 16px '微软雅黑';
    color: #2b2b2b;
    padding: 10px 0;
}

.tools {

    padding-top: 10px;
    display: flex;
    align-items: center;
    flex-direction: column;

    & p {
        margin-bottom: 5px;
        font: 16px '微软雅黑';
        color: #f54343;
    }
}

a.toupiao {
    display: block;
    width: 118px;
    height: 26px;
    font: 12px '微软雅黑';
    line-height: 25px;
    border: 1px solid #f54343;
    text-align: center;
    border-radius: 14px;
    color: #f54343;
    text-decoration: none;
}