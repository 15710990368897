.ActionLeague {
    width: 800px;
    margin: 0 auto;
    margin-bottom: 60px;
    & .slideItem {
        position: relative;
    }
    & [ifeng_ui_pc_sliders-arrows_pre] {
        width: 25px !important;
        height: 32px !important;
        position: absolute !important;
        top: 50% !important;
        margin-top: -10px !important;
        left: -35px !important;
        cursor: pointer !important;
        z-index: 100 !important;
        background: rgba(0, 0, 0, 0) url(http://p0.ifengimg.com/fe/gongyi_home_page/images/car4-left-arrow_d1ab852b.jpg)
            no-repeat center center !important;
        background-size: contain !important;
    }
    & [ifeng_ui_pc_sliders-arrows_next] {
        right: -35px !important;
        width: 25px !important;
        height: 32px !important;
        position: absolute !important;
        top: 50% !important;
        margin-top: -10px !important;
        cursor: pointer !important;
        z-index: 100 !important;
        background: rgba(0, 0, 0, 0)
            url(http://p0.ifengimg.com/fe/gongyi_home_page/images/car4-right-arrow_b526e70a.jpg) no-repeat center center !important;
        background-size: contain !important;
    }
}
.text_wrap {
    width: 100%;
    height: 155px;
    position: absolute;
    left: 0;
    bottom: 28px;
}
.text {
    /* width: 364px; */
    font-size: 1.875rem;
    width: 100%;
    margin: 0 auto;
    padding: 0 24px;
    color: #b79e6f;
    margin-top: 24px;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-box-orient: vertical;
    -webkit-line-clamp: 1;
    position: absolute;
    left: 0;
    top: 0;
    & a {
        display: block;
        width: 94%;
        overflow: hidden;
        white-space: nowrap;
        text-overflow: ellipsis;
        color: #b79e6f;
        text-decoration: none;
        &:hover {
            color: #fff;
        }
    }
}
.text_content {
    position: absolute;
    top: 75px;
    font-size: 0.875rem;
    width: 95%;
    margin: 0 auto;
    padding: 0 24px;
    color: #fff;
    line-height: 22px;
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-box-orient: vertical;
    -webkit-line-clamp: 3;
    font-weight: normal;
    height: 66px;
}
.text_bg {
    background-color: #000;
    opacity: 0.6;
    filter: alpha(opacity=60);
    width: 100%;
    height: 100%;
}
[ifeng_ui_pc_sliders-dots] {
    position: absolute;
    left: 50% !important;
    bottom: -40px !important;
    right: auto !important;
    margin-left: -80px !important;
}
.dot {
    width: 10px !important;
    height: 10px !important;
    background-color: #3b3b3b !important;
    -webkit-border-radius: 50%;
    -moz-border-radius: 50%;
    border-radius: 50%;
    margin-right: 24px !important;
    float: left;
}
.current {
    width: 10px !important;
    height: 10px !important;
    background-color: #f54343 !important;
    -webkit-border-radius: 50%;
    -moz-border-radius: 50%;
    border-radius: 50%;
    margin-right: 24px !important;
    float: left;
}
